import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

const updateUser = async (userEmail, updatedUserData) => {
    try {
        const userDocRef = doc(db, 'nutritionist', userEmail);
        await updateDoc(userDocRef, updatedUserData);
        return true;
    } catch (error) {
        console.error("Erro ao atualizar o usuário. " + error)
        return false;
    }
};

export default updateUser;
