import { collection, query, where, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { formatDate } from './../../utils/dateUtils';

export async function getPendingValidationDiets() {
  const dietsRef = collection(db, 'diets');
  const q = query(dietsRef, where('status', '==', 'pending_validation'));

  try {
    const querySnapshot = await getDocs(q);
    const pendingValidationDiets = [];

    querySnapshot.forEach((doc) => {
      const dietData = doc.data();
      dietData.id = doc.id;
      dietData.createdAt = formatDate(dietData.createdAt)
      pendingValidationDiets.push(dietData);
    });

    return pendingValidationDiets;
  } catch (error) {
    console.error('Erro ao buscar as dietas com status "pending_validation":', error);
    return [];
  }
}

export async function getDiet(id) {
  const dietRef = doc(db, 'diets', id);

  try {
    const docSnap = await getDoc(dietRef);
    if (docSnap.exists()) {
      const dietData = docSnap.data();
      return { id: docSnap.id, ...dietData };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar a diet por id na base de dados:', error);
    return null;
  }
}



export async function updateDietById(dietId, diet) {
  const dietRef = doc(db, 'diets', dietId);

  try {
    await updateDoc(dietRef, {
      status: 'validated',
      payment_was_made: false,
      diet: diet
    });
  } catch (error) {
    console.error('Erro ao atualizar o status da dieta:', error);
  }
}

export async function updateStatusDietById(dietId) {
  const dietRef = doc(db, 'diets', dietId);

  try {
    await updateDoc(dietRef, {
      status: 'pending_validation',
    });
    
  } catch (error) {
    console.error('Erro ao atualizar o status da dieta:', error);
  }
}

export async function updateDietForRequestValidation(dietId) {
  const dietRef = doc(db, 'diets', dietId);

  try {
    await updateDoc(dietRef, {
      payment_was_made: false,
      status: 'pending_validation'
    });
  } catch (error) {
    console.error('Erro ao atualizar o status da dieta:', error);
  }
}
