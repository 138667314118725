import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { formatDate } from "../../utils/dateUtils";
import { endOfDay, subMonths, subDays } from 'date-fns';

export async function getValidationByDietId(dietId) {
    const validationRef = collection(db, 'validation');
    const q = query(validationRef,
        where('status', '==', 'pending_validation'),
        where('diet_id', '==', dietId)
    );

    try {
        const querySnapshot = await getDocs(q);
        const pendingValidations = [];

        querySnapshot.forEach((doc) => {
            const validationDB = doc.data();

            const validation = {
                id: validationDB.id,
                nutritionist_email: validationDB.nutritionist_email,
                status: validationDB.status,
                diet_id: validationDB.diet_id,
                description: validationDB.description,
                createdAt: validationDB.createdAt,
                updatedAt: validationDB.updatedAt,
                user_email: validationDB.user_email
            }
            validation.id = doc.id;


            pendingValidations.push(validation);
        });

        return pendingValidations;
    } catch (error) {
        return [];
    }
}

export async function getValidationByNutri(email) {
    const validationRef = collection(db, 'validation');
    const q = query(validationRef,
        where('nutritionist_email', '==', email)
    );

    try {
        const querySnapshot = await getDocs(q);
        const validations = [];

        querySnapshot.forEach((doc) => {
            const validation = doc.data();
            validation.id = doc.id;
            validation.updated_at = formatDate(validation.updated_at)
            validations.push(validation);
        });

        return validations;
    } catch (error) {
        return [];
    }
}


export async function getValidationsByInterval(email, isMonthActual, isLastMonth) {
    const validationRef = collection(db, 'validation');

    const q = query(validationRef,
        where('nutritionist_email', '==', email),
        where('status', '==', 'validated'),
        where('payment_was_made', '==', true));

    try {
        const querySnapshot = await getDocs(q);
        const validations = [];
        let start, end;

        if (isMonthActual) {
            const currentDate = new Date();
            start = subDays(currentDate, 30); // Subtrai 30 dias da data atual
            end = endOfDay(currentDate);
        } else if (isLastMonth) {
            const currentDate = new Date();
            const lastMonth = subMonths(currentDate, 1);
            start = subDays(lastMonth, 30); // Subtrai 30 dias do último mês
            end = endOfDay(lastMonth);
        }

        querySnapshot.forEach((doc) => {
            try {
                const validation = doc.data();
                const dateParts = formatDate(validation.updated_at).split('-');
                const year = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1] - 1, 10);
                const day = parseInt(dateParts[2], 10);
                const updatedAt = new Date(year, month, day);
                if (updatedAt >= start && updatedAt <= end) {
                    validation.id = doc.id;
                    validation.updated_at = formatDate(validation.updated_at);
                    validations.push(validation);
                }
            } catch (error) {
                console.error(error);
                console.error("Esse objeto contém erro: " + doc.id);
            }
        });

        return validations;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getValidationsByFilter(email, startDate, endDate, isPaid) {
    const validationRef = collection(db, 'validation');

    const q = query(validationRef,
        where('nutritionist_email', '==', email),
        where('status', '==', 'validated'),
        where('payment_was_made', '==', isPaid));

    try {
        const querySnapshot = await getDocs(q);
        const validations = [];
        const start = new Date(startDate);
        const end = new Date(endDate);
        querySnapshot.forEach((doc) => {
            try {
                const validation = doc.data();
                const updatedAt = new Date(formatDate(validation.updated_at));

                if (updatedAt >= start && updatedAt <= end) {
                    validation.id = doc.id
                    validation.updated_at = formatDate(validation.updated_at)
                    validations.push(validation)
                }
            } catch (error) {
                console.error(error)
                console.error("Esse objeto contém erro: " + doc.id);
            }
        });

        return validations;
    } catch (error) {
        return [];
    }
}

export async function getValidationsByEmailAndStatus(email, isPaid) {
    const validationRef = collection(db, 'validation');
    const q = query(validationRef,
        where('status', '==', 'validated'),
        where('payment_was_made', '==', isPaid),
        where('nutritionist_email', '==', email)
    );

    try {
        const querySnapshot = await getDocs(q);
        const monthlyData = {};

        querySnapshot.forEach((doc) => {
            const validation = doc.data();
            const updatedAt = new Date(validation.updated_at.toDate());
            const month = updatedAt.getMonth() + 1;

            if (!monthlyData[month]) {
                monthlyData[month] = parseFloat(validation.amount);
            } else {
                monthlyData[month] += parseFloat(validation.amount);
            }
        });

        const result = [['Task', 'Gráfico ao mês']];

        if (monthlyData[1]) result.push(['Janeiro', monthlyData[1]]);
        if (monthlyData[2]) result.push(['Fevereiro', monthlyData[2]]);
        if (monthlyData[3]) result.push(['Março', monthlyData[3]]);
        if (monthlyData[4]) result.push(['Abril', monthlyData[4]]);
        if (monthlyData[5]) result.push(['Maio', monthlyData[5]]);
        if (monthlyData[6]) result.push(['Junho', monthlyData[6]]);
        if (monthlyData[7]) result.push(['Julho', monthlyData[7]]);
        if (monthlyData[8]) result.push(['Agosto', monthlyData[8]]);
        if (monthlyData[9]) result.push(['Setembro', monthlyData[9]]);
        if (monthlyData[10]) result.push(['Outubro', monthlyData[10]]);
        if (monthlyData[11]) result.push(['Novembro', monthlyData[11]]);
        if (monthlyData[12]) result.push(['Dezembro', monthlyData[12]]);

        if (result.length === 1) {
            result.push(['Não contém dados', 1]);
        }

        return result;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function updateValidationStatus(validation, email) {
    const validationRef = doc(db, 'validation', validation.id);
    try {
        await updateDoc(validationRef, {
            status: 'validated',
            nutritionist_email: email,
            updated_at: new Date()
        });
    } catch (error) {
        console.error('Erro ao atualizar o status da validation:', error);
    }
}
