import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase"; 

export async function getAnamnesis(dietId) {
    const dietsRef = collection(db, 'anamnesis');
    const q = query(dietsRef, where('diet_id', '==', dietId));
  
    try {
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.size === 0) {
        return null;
      }
  
      const doc = querySnapshot.docs[0];
  
      return doc.data();
    } catch (error) {
      console.error('Erro ao buscar as dietas com status "pending_validation":', error);
      return null;
    }
}
