import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Row from './row';
import Paper from '@mui/material/Paper';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import { getPendingValidationDiets, updateDietById } from '../../services/firebase/diets';
import { getCustomerByEmail, getCustomers, updateCustomer } from '../../services/firebase/customers';
import { mergeUserWithDiet } from '../../services/customer/customer';
import { getAnamnesis } from './../../services/firebase/anamnesis';
import AnamneseDialog from '../../components/material/anamnesis';
import TabsComponent from '../../components/tabs/internal';
import { getValidationByDietId, updateValidationStatus } from '../../services/firebase/validation';
import CustomAlert from './../../components/material/alert'
import { useUser } from '../../components/userContext';
import { useNavigate } from 'react-router-dom';
import { getUserFromSessionStorage } from './../../utils/userUtils'

export default function FoodMenus() {
  const navigate = useNavigate()

  const [page, setPage] = useState(0);
  const defaultRowsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const [loading, setLoading] = useState(true);
  const [selectedAnamnese, setSelectedAnamnese] = useState({});
  const [anamneseDialogOpen, setAnamneseDialogOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectedDiet, setSelectedDiet] = useState('');
  const [showTabs, setShowTabs] = useState(false);
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [mergeUsersWithDiets, setMergeUsersWithDiets] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [severityAlert, setSeverityAlert] = useState('error');
  const [userLogged, setUserLogged] = useState(null)
  const [updateTable, setUpdateTable] = useState(false);
  const { user } = useUser();

  const processAnamnesis = (anamnesis, username) => {
    setAnamneseDialogOpen(true);
    setSelectedUsername(username)
    setSelectedAnamnese(anamnesis);
  };

  const initValidation = (diet) => {
    setSelectedDiet(diet)
    setShowTabs(true);
  };

  const hideAlert = () => {
    setShowAlert(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDietChange = async (diet, dietId, validation) => {
    try {
      const customer = await getCustomerByEmail(validation.user_email);

      setLoading(true)
      if (userLogged) {
        await updateDietById(dietId, diet)
        await updateValidationStatus(validation, userLogged.email)
        await updateCustomer(validation.email)
        const index = mergeUsersWithDiets.findIndex(
          (user) => user.diets.includes(diet)
        );

        if (index !== -1) {
          mergeUsersWithDiets[index].diets.shift();
          setMergeUsersWithDiets([...mergeUsersWithDiets]);
          setQueryExecuted(false);
        }

        const message = `Validação da dieta ${customer.gender === 'Masculino' ? 'do' : 'da'} ${customer.name} foi concluída com sucesso!`;

        setSeverityAlert('success')
        setMessageAlert(message)
        setShowAlert(true)
      } else {
        navigate('/')
      }

    } catch (error) {
      console.erro('Erro ao tentar atualizar a dieta: ' + error)
    } finally {
      setLoading(false);
      setUpdateTable(true)
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (user) {
          setUserLogged(user);
        } else {
          const userData = getUserFromSessionStorage();
          setUserLogged(userData);
          if (!userData) {
            navigate('/');
          }
        }

        const diets = await getPendingValidationDiets();
        const customersData = await getCustomers();

        if (diets.length === 0) {
          return;
        } else {
          for (const diet of diets) {
            const anamnesis = await getAnamnesis(diet.id);
            diet.anamnesis = anamnesis;
            const validationDB = await getValidationByDietId(diet.id);
            diet.validation = validationDB;
          }

          const mergedData = await mergeUserWithDiet(customersData, diets);
          setMergeUsersWithDiets(mergedData);
        }
      } catch (error) {
        console.error('Erro na busca de dietas ou clientes:', error);
      }
    }

    //ADICIONAR ISSO EM TODAS AS REQUESTS REALIZADAS NO FIREBASE
    if (!queryExecuted) {
      fetchData();
      setQueryExecuted(true);
    }

    if (updateTable) {
      window.location.reload()
      setUpdateTable(false)
    }
    setLoading(false);
  }, [queryExecuted, user, updateTable, navigate]);


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      margin="0 auto"
      width="900px"
    >
      <CssBaseline />
      {showAlert && (
        <CustomAlert
          transform='translateY(-220px)'
          variant='outlined'
          severity={severityAlert}
          message={messageAlert}
          onClose={hideAlert}
        />
      )}
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight="-770px"
          marginTop="35px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={4}
          style={{
            maxWidth: '100%',
            overflow: 'auto'
          }}>
          <br />
          <br />
          <br />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              marginLeft: '28px'
            }}
          >
            Cardápios disponíveis
          </Typography>
          <Box
            display="flex"
            mt={-2}
            sx={{
              justifyContent: "flex-end"
            }}
          >
            <Tooltip title="Atualizar">
              <Button onClick={() => window.location.reload()}>
                <CachedOutlinedIcon />
              </Button>
            </Tooltip>
          </Box>
          <Divider
            sx={{
              marginTop: '40px',
              borderBottom: '2px solid #E0E0E0',
            }}
          />
          <Table
            aria-label="collapsible table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '100px' }}></TableCell>
                <TableCell style={{ width: '200px' }}>Nome</TableCell>
                <TableCell style={{ width: '200px' }}>Data de nascimento</TableCell>
                <TableCell style={{ width: '200px' }}>Gênero</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergeUsersWithDiets && mergeUsersWithDiets.length > 0 ? (
                mergeUsersWithDiets.map((user) => (
                  <Row
                    user={user}
                    diets={user.diets}
                    processAnamnesis={processAnamnesis}
                    initValidation={initValidation}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      fontWeight: 'bold'
                    }}>
                    Todos os cardápios foram validados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={mergeUsersWithDiets.length}
            labelRowsPerPage="Linhas por página"
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {anamneseDialogOpen && (
        <AnamneseDialog
          open={anamneseDialogOpen}
          onClose={() => setAnamneseDialogOpen(false)}
          anamnese={selectedAnamnese}
          username={selectedUsername}
        />
      )}
      {showTabs && (
        <TabsComponent
          diet={selectedDiet}
          onSave={handleDietChange}
          open={showTabs}
          onClose={() => setShowTabs(false)}
        />
      )}
    </Box>
  );
}
