import { addDoc, collection } from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import CustomAlert from "../material/alert";

export const saveValidation = async (validation) => {
    try {
        const validationCollection = collection(db, 'validation');

        const docRef = await addDoc(validationCollection, {
            amount: validation.amount,
            createdAt: validation.createdAt,
            description: validation.description,
            diet_id: validation.diet_id,
            payment_was_made: false,
            status: validation.status,
            user_email: validation.user_email
        });

        return docRef.id;
    } catch (error) {
        return <CustomAlert
            transform='translateY(-220px)'
            variant='outlined'
            severity='error'
            message='Erro ao tentar salvar transação. Tire um print dessa tela e entre em contato conosco.'
        />
    }
};