import { collection, query, where, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase"; 

export async function getCustomers() {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('contains_diet_for_validation', '==', true));

  try {
    const querySnapshot = await getDocs(q);
    const users = [];

    querySnapshot.forEach((doc) => {
      const user = doc.data();
      users.push(user);
    });

    return users;
  } catch (error) {
    console.error('Erro ao buscar as dietas com status "pending_validation":', error);
    return [];
  }
}

export async function updateCustomer(email) {
  const usersRef = doc(db, 'users', email);

  try {
    await updateDoc(usersRef, {
      contains_diet_for_validation: false
    });
  } catch (error) {
    console.error('Erro ao atualizar o status do customer:', error);
  }
}

export async function enableUserToValidateDiet(email) {
  const usersRef = doc(db, 'users', email);

  try {
    await updateDoc(usersRef, {
      contains_diet_for_validation: true
    });
  } catch (error) {
    console.error('Erro ao atualizar o status do customer:', error);
  }
}

export async function getCustomerByEmail(email) {
  const usersRef = doc(db, 'users', email);

  try {
    const docSnap = await getDoc(usersRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar o customer na base de dados:', error);
    return null;
  }
}
