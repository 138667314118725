import React, { useState, useEffect } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../components/material/copyright';
import { v4 as uuid } from "uuid";
import registerUser from '../../services/user/register';
import CustomAlert from './../../components/material/alert';
import { withoutValue } from '../../utils/valueUtils';
import buttonStyle from './../../global/style/button';
import icon from './../../assets-screens/icon.png'

export default function Register() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [password, setPassword] = useState('');
  const [crn, setCrn] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [severity, setSeverity] = useState('error');

  const [redirectToLogin, setRedirectToLogin] = useState(false);


  const hideAlert = () => {
    if (severity.includes('success')) {
      navigate('/login');
    }
    setShowAlert(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const legalEntity = cnpj ? true : false;

    const user = {
      id: uuid(),
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      crn: crn,
      legalEntity: legalEntity,
      cpf: cpf,
      cnpj: cnpj,
      status: "PENDING"
    }

    if (withoutValue(firstName)) {
      setMessage("Informe seu NOME.")
      setShowAlert(true)
      return;
    }

    if (withoutValue(lastName)) {
      setMessage("Informe seu SOBRENOME.")
      setShowAlert(true)
      return;
    }

    if (withoutValue(email)) {
      setMessage("Informe seu EMAIL.")
      setShowAlert(true)
      return;
    }

    if (withoutValue(password)) {
      setMessage("Informe sua SENHA.")
      setShowAlert(true)
      return;
    }

    if (withoutValue(crn)) {
      setMessage("Informe seu CRN.")
      setShowAlert(true)
      return;
    }

    if (withoutValue(cnpj) && withoutValue(cpf)) {
      setMessage("Informe um CPF ou CNPJ.")
      setShowAlert(true)
      return;
    }

    if(withoutValue(whatsapp)) {
      setMessage("Informe seu whatsapp para entrarmos em contato.")
      setShowAlert(true)
      return;
    }

    registerUser(user)
      .then(() => {
        setMessage('Aguarde até 1 dia útil para receber nosso contato.')
        setShowAlert(true)
        setSeverity('success')

        setTimeout(() => {
          setRedirectToLogin(true);
        }, 10000);
      }).catch((error) => {
        setMessage('Erro. Tente novamente mais tarde.')
        setShowAlert(true)
      })

  };


  useEffect(() => {
    if (redirectToLogin) {
      navigate('/login');
    }
  }, [redirectToLogin, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      {showAlert && (
        <CustomAlert
          variant='outlined'
          severity={severity}
          message={message}
          onClose={hideAlert}
        />
      )}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={icon}
          alt="Avatar"
          sx={{
            width: 100,
            height: 100,
            marginBottom: 1,
            backgroundColor: 'transparent'
          }}

        />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 1
          }}
        >
          Solicitar acesso
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
                id="firstName"
                label="Primeiro nome"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={(e) => setLastName(e.target.value)}
                id="lastName"
                label="Sobrenome"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={(e) => setWhatsapp(e.target.value)}
                id="whatsapp"
                label="Whatsapp"
                name="whatsapp"
                type="phone"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={(e) => setCrn(e.target.value)}
                name="crn"
                label="CRN"
                type="crn"
                id="crn"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={(e) => setCpf(e.target.value)}
                name="cpf"
                label="CPF"
                type="cpf"
                id="cpf"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={(e) => setCnpj(e.target.value)}
                name="cnpj"
                label="CNPJ"
                type="cnpj"
                id="cnpj"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ...buttonStyle, mt: 3, mb: 2 }}
          >
            Solicitar acesso
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                to="/login"
                variant="body2"
                onClick={() => navigate('/login')}
                style={{ cursor: 'pointer' }}
              >
                Já tem conta? Faça login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}