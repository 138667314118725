export async function mergeUserWithDiet(customers, diets) {
    const transformedData = [];

    customers.forEach((customer) => {
        const userDiets = diets.filter((diet) => diet.email === customer.email);
        if (userDiets.length > 0) {

            const birthDate = customer.birthdate ? customer.birthdate.split('/').reverse().join('-') : null;

            const userObject = {
                name: customer.name,
                gender: customer.gender,
                birthDate: birthDate,
                diets: userDiets,
            };

            transformedData.push(userObject);
        }
    });

    return transformedData;
}
