import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Autocomplete,
  Box,
  CssBaseline,
  Paper,
  TableContainer,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Banner from '../../components/material/banner';
import { allMonthNames } from '../../utils/months';
import ComparisonChart from '../../components/material/chart';
import { getUserFromSessionStorage } from '../../utils/userUtils';
import { getValidationsByInterval } from '../../services/firebase/validation';

export default function Payments() {
  dayjs.locale('pt-br')
  const navigate = useNavigate();

  const date = new Date();
  const monthName = date.toLocaleString('pt-BR', { month: 'long' });

  const currentMonth = monthName;

  const [lastMonth, setLastMonth] = useState(monthName)
  const [valueByLastMonth, setValueByLastMonth] = useState(0.0);
  const [currentValue, setCurrentValue] = useState(0.0);
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [queryLastMonthExecutedFirst, setQueryLastMonthExecutedFirst] = useState(false);

  const populateValue = async (validations, isCurrentValue) => {
    let sum = 0.0;
    if (validations) {
      validations.forEach((validation) => {
        sum += parseFloat(validation.amount);
      });

      if (isCurrentValue) {
        setCurrentValue(sum);
      } else {
        setValueByLastMonth(sum)
      }
    } else {
      if (isCurrentValue) {
        setCurrentValue('0');
      } else {
        setValueByLastMonth('0')
      }
    }
  }

  const handleSearch = (newMonth) => {
    setQueryExecuted(false)
    setLastMonth(newMonth)
  };



  const handleLastMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1)
    const monthName = date.toLocaleString('pt-BR', { month: 'long' });
    setLastMonth(monthName);
    setQueryLastMonthExecutedFirst(true)
  }

  useEffect(() => {
    async function fetchData() {
      const userData = getUserFromSessionStorage();
      if (!userData) {
        navigate('/');
        return;
      }

      const validationsByThisMonth = await getValidationsByInterval(
        userData.email,
        true,
        false
      );

      await populateValue(validationsByThisMonth, true);

      const validationsByLastMonth = await getValidationsByInterval(
        userData.email,
        false,
        true
      );

      await populateValue(validationsByLastMonth, false);
    }

    if (!queryExecuted) {
      fetchData();
      setQueryExecuted(true);
    }

    if (!queryLastMonthExecutedFirst) {
      handleLastMonth()
    }
  }, [queryExecuted, navigate, queryLastMonthExecutedFirst]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      margin="0 auto"
      width="900px"
      marginTop="80px"
    >
      <CssBaseline />
      <TableContainer
        component={Paper}
        elevation={4}
        style={{
          maxWidth: '100%',
          overflow: 'auto'
        }}>
        <Box
          sx={{
            marginBottom: '-150px'
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              marginTop: '40px',
              marginBottom: '-130px',
              padding: '32px'
            }}
          >
            Mensais
          </Typography>
          <Banner
            monthName={lastMonth}
            valueByLastMonth={valueByLastMonth}
            currentValue={currentValue}
          />
        </Box>
        <Box
          sx={{
            width: '25%',
            padding: 4,
          }}
        >
          <Autocomplete
            id="month-filter"
            options={allMonthNames}
            getOptionLabel={(option) => option}
            value={lastMonth}
            noOptionsText={lastMonth}
            onChange={(event, newMonth) => {
              handleSearch(newMonth);
            }}
            sx={{
              width: '157px',
              marginTop: '150px'
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mês"
                margin="normal"
              />
            )}
          />
        </Box>

        <Box
          padding="25px"
          width="100%"
        >
          <ComparisonChart
            monthOneName={lastMonth}
            monthOneValue={valueByLastMonth}
            monthTwoName={currentMonth}
            monthTwoValue={currentValue}
          />
        </Box>
      </TableContainer>
    </Box>
  );
}