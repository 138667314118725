import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Button } from '@mui/material';
import buttonStyle from '../../global/style/button';
import { ExpandMore } from '@mui/icons-material';

function Row(props) {
  const { user, processAnamnesis, initValidation } = props;
  const [open, setOpen] = useState(false);
  const [titleDiet, setTitleDiet] = useState('');

  useEffect(() => {
    if (user && user.diets && user.diets.lenth > 1) {
      setTitleDiet('Dietas')
    } else {
      setTitleDiet('Dieta')
    }
  }, [user]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <ExpandMore
            aria-label="expand row"
            size="small"
            sx={{
              color: 'black',
              cursor: 'pointer'
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ExpandMore>
        </TableCell>
        <TableCell align="left">
          {user.name}
        </TableCell>
        <TableCell align="left" style={{ paddingLeft: '40px' }}>
          {user.birthDate}
        </TableCell>
        <TableCell align="left" style={{ paddingLeft: '10px' }}>
          {user.gender}
        </TableCell>
      </TableRow>
      {user.diets && user.diets.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {titleDiet}
                </Typography>
                <Table size="small" aria-label="diets">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data de criação</TableCell>
                      <TableCell>Objetivo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.diets.map((diet) => (
                      <TableRow key={diet.id}>
                        <TableCell>{diet.createdAt}</TableCell>
                        <TableCell style={{ width: '29%' }}>{diet.goal}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => initValidation(diet)}
                            sx={buttonStyle}
                          >
                            Dieta
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => processAnamnesis(diet.anamnesis.anamnesis, user.name)}
                            sx={buttonStyle}
                          >
                            Anamnese
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default Row;
