import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

export default function Banner(props) {
  const { monthName, valueByLastMonth, currentValue } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 6,
        paddingRight: 7
      }}
    >
      <Paper
        elevation={10}
        sx={{
          backgroundColor: '#248f4e',
          color: 'white',
          width: 220,
          height: 118,
          margin: 10
        }}
      >
        <Box
          sx={{
            height: '100%'
          }}
        >
          <Typography padding="20px">Total em {monthName}</Typography>
          <Box sx={{ paddingLeft: 3 }}>R$ {valueByLastMonth}</Box>
        </Box>
      </Paper>
      <Paper
        elevation={10}
        sx={{
          backgroundColor: '#248f4e',
          color: 'white',
          width: 220,
          height: 118,
          margin: 10
        }}
      >
        <Box
          sx={{
            height: '100%'
          }}
        >
          <Typography padding="20px">Total esse mês</Typography>
          <Box sx={{ paddingLeft: 3 }}>R$ {currentValue}</Box>
        </Box>
      </Paper>
    </Box>
  );
}
