const buttonStyle = {
    borderColor: '#99A1A8',
    backgroundColor: '#248f4e',
    color: '#FFF',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#1b702e',
    },
    '&:active': {
        backgroundColor: '#165624',
    },
};

export default buttonStyle;
