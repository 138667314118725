import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useUser } from '../userContext';
import buttonStyle from '../../global/style/button';
import { Transition } from '../transition';
import updateUser from '../../services/user/update';
import { Alert, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getUserFromSessionStorage } from '../../utils/userUtils';
import { useNavigate } from 'react-router-dom';
import { getNutritionist } from './../../services/firebase/nutritionist';

export default function ProfileEditDialog({ open, onClose }) {
    const user = useUser();

    const navigate = useNavigate();

    const [queryExecuted, setQueryExecuted] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isOpenError, setIsOpenError] = useState(false);
    const [userData, setUserData] = useState({
        cpf: '',
        cnpj: '',
        email: '',
        whatsapp: '',
        firstName: '',
        lastName: '',
        password: '',
        crn: ''
    });

    useEffect(() => {
        async function fetchData() {
            try {

                if (user && user.user) {
                    setUserData({
                        cpf: user.user.cpf,
                        cnpj: user.user.cnpj || '',
                        email: user.user.email,
                        whatsapp: user.user.whatsapp,
                        firstName: user.user.firstName,
                        lastName: user.user.lastName,
                        password: user.user.password,
                        crn: user.user.crn
                    });
                } else {
                    const userFirebase = await getUserFromSessionStorage();
                    if (!userFirebase) {
                        navigate('/');
                        return;
                    }
                    const user = await getNutritionist(userFirebase.email);
                    setUserData({
                        cpf: user.cpf,
                        cnpj: user.cnpj || '',
                        email: user.email,
                        whatsapp: user.whatsapp,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        password: user.password,
                        crn: user.crn
                    });
                }
            } catch (error) {
                console.error("Erro: " + error)
            }
        }

        if (!queryExecuted) {
            fetchData()
            setQueryExecuted(true)
        }
    }, [user, queryExecuted, navigate]);

    const handleClose = () => {
        onClose();
    };

    const handleCloseError = () => {
        setIsOpenError(false);
    };

    const handleCloseSuccess = () => {
        setIsUpdateSuccess(false)
    }

    const handleFieldChange = (field, value) => {
        setUserData({ ...userData, [field]: value });
        setIsSaveButtonDisabled(JSON.stringify(userData) === JSON.stringify({ ...userData, [field]: value }));
    };

    const handleSave = async () => {
        try {
            const hasUpdated = updateUser(userData.email, userData);
            if (hasUpdated) {
                setIsUpdateSuccess(hasUpdated);
                setIsSaveButtonDisabled(hasUpdated)
            } else {
                setIsOpenError(true);
                setIsSaveButtonDisabled(false);
            }
        } catch (error) {
            console.error('Erro ao atualizar dados. ' + error);
            setIsOpenError(true);
            setIsSaveButtonDisabled(false);
        }
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Snackbar
                    open={isOpenError}
                    autoHideDuration={6000}
                    onClose={handleCloseError}
                    TransitionComponent={Transition}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Alert
                        onClose={handleCloseError}
                        severity="error"
                    >
                        Erro ao atualizar seus dados, tente novamente mais tarde.
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={isUpdateSuccess}
                    autoHideDuration={6000}
                    onClose={handleCloseSuccess}
                    TransitionComponent={Transition}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Alert
                        onClose={handleCloseSuccess}
                        severity="success"
                    >
                        Dados atualizados com sucesso!.
                    </Alert>
                </Snackbar>
                <DialogTitle>Editar Perfil</DialogTitle>
                <IconButton
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText>
                        Edite seus dados se necessário:
                    </DialogContentText>
                    <TextField
                        label="Nome"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.firstName}
                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                    />
                    <TextField
                        label="Sobrenome"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.lastName}
                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                    />
                    <TextField
                        label="CPF"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.cpf}
                        title="Contate-nos para alterar."
                        disabled={true}
                        onChange={(e) => handleFieldChange('cpf', e.target.value)}
                    />
                    {user.legalEntity ? (
                        <TextField
                            label="CNPJ"
                            fullWidth
                            margin="normal"
                            variant="standard"
                            value={user.cnpj}
                            title="Contate-nos para alterar."
                            disabled={true}
                            onChange={(e) => handleFieldChange('cnpj', e.target.value)}
                        />
                    ) : null}
                    <TextField
                        label="CRN"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.crn}
                        title="Contate-nos para alterar."
                        disabled={true}
                    />
                    <TextField
                        label="E-mail"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.email}
                        title="Contate-nos para alterar."
                        disabled={true}
                    />
                    <TextField
                        label="Whatsapp com DDD"
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.whatsapp}
                        onChange={(e) => handleFieldChange('whatsapp', e.target.value)}
                    />
                    <TextField
                        label="Senha"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        variant="standard"
                        value={userData.password}
                        onChange={(e) => handleFieldChange('password', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ ...buttonStyle }}
                        onClick={handleSave}
                        disabled={isSaveButtonDisabled}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}
