import { CardPayment, initMercadoPago } from '@mercadopago/sdk-react';
import React, { useEffect, useState } from 'react';
import { fetchPaymentData, saveTransactionData } from './../../components/payments/';
import { v4 as uuidv4 } from 'uuid'; // Importe v4 como uuidv4
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import CustomAlert from '../../components/material/alert';
import { getDiet, updateStatusDietById } from './../../services/firebase/diets';
import { saveValidation } from '../../components/validation';
import { enableUserToValidateDiet } from './../../services/firebase/customers';
import { getConfigEmail } from '../../services/firebase/config_email';
import { getActiveNutritionists } from '../../services/firebase/nutritionist';
import { sendEmail } from '../../components/notify/email/send-email';

export default function PaymentApp() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const publicKey = queryParameters.get('publicKey');
    const accessToken = queryParameters.get('accessToken');
    const description = queryParameters.get('description');
    const dietId = queryParameters.get('dietId');
    const locale = queryParameters.get('locale');

    initMercadoPago(publicKey);

    const [referenceId, setReferenceId] = useState(null);
    const [amount, setAmount] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [severityAlert, setSeverityAlert] = useState('error')
    const [messageAlert, setMessageAlert] = useState('Ocorreu um erro no pagamento, tente novamente mais tarde.')
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('');
    const [formTitle, setFormTitle] = useState('');
    const [formSubmit, setFormSubmit] = useState('');

    const navigate = useNavigate();

    const executePayment = async (requestData) => {
        try {
            const {
                token, issuer_id,
                payment_method_id, transaction_amount,
                installments
            } = requestData;


            const { email } = requestData.payer;

            const requestBody = {
                transaction_amount: transaction_amount,
                token: token,
                installments: installments,
                payment_method_id: payment_method_id,
                issuer_id: issuer_id,
                payer: {
                    email: email
                }
            };

            const idempotencyKey = uuidv4();

            const response = await fetch('https://api.mercadopago.com/v1/payments', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-Idempotency-Key': idempotencyKey
                },
                body: JSON.stringify(requestBody)
            });
            const diet = await getDiet(dietId);

            if (response.ok) {
                const data = await response.json();
                if (data.status !== 'rejected') {
                    setSeverityAlert('success')
                    setMessageAlert('Processando pagamento! Volte ao aplicativo Dietfy e aguarde!')
                    setShowAlert(true)
                    const validation = {
                        amount: amount,
                        createdAt: new Date(),
                        description: description,
                        diet_id: dietId,
                        payment_was_made: false,
                        user_email: diet.email,
                        status: 'pending_validation'
                    }

                    await saveValidation(validation);
                    await updateStatusDietById(dietId);

                    const transaction = {
                        diet_id: dietId,
                        user_email: diet.email,
                        created_at: new Date(),
                        description: 'request to MP with success',
                        data: data,
                        id: data.id
                    }

                    await saveTransactionData(transaction)

                    await enableUserToValidateDiet(diet.email)
                    sendEmailToActivesNutritionists()
                } else {
                    setSeverityAlert('error')
                    setMessageAlert('Pagamento rejeitado! Tente novamente mais tarde!')
                    setShowAlert(true)
                }
            } else {
                setShowAlert(true)
                const validation = {
                    amount: amount,
                    createdAt: new Date(),
                    description: description,
                    diet_id: diet.id,
                    payment_was_made: false,
                    user_email: diet.email,
                    status: 'error'
                }

                await saveValidation(validation);
            }

        } catch (error) {
            console.error('Erro ao realizar pagamento:', error);
        }

    };

    const sendEmailToActivesNutritionists = async () => {
        const activeNutritionists = await getActiveNutritionists()
        const configEmail = await getConfigEmail();
        const recipients = activeNutritionists.map((nutritionist) => nutritionist.email);

        sendEmail(
            recipients,
            configEmail.serviceId,
            configEmail.templateId,
            configEmail.userId
        );

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!publicKey || !accessToken || !dietId || !description || !locale) {
                    navigate('/');
                    return;
                }

                const { price } = await fetchPaymentData();
                setAmount(price)

                setReferenceId(uuidv4());
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDataAndSetPaymentData = async () => {
            if (!amount) {
                await fetchData();
            } else {
                setLoading(false);
            }
        };

        const normalizeLocale = () => {
            if (locale === 'en') {
                setFormTitle('Dietfy - Credit Card')
                setFormSubmit('Make Payment')
                setLanguage('en-US')
            }

            if (locale === 'pt') {
                setFormTitle('Dietfy - Cartão de Crédito')
                setFormSubmit('Efetuar Pagamento')
                setLanguage('pt-BR')
            }
        }

        fetchDataAndSetPaymentData();
        normalizeLocale()

    }, [accessToken, publicKey, dietId, description, amount, navigate, locale, language]);


    const hideAlert = () => {
        setShowAlert(false);
    }

    return (
        <Box>
            {loading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="100vh"
                    margin="0 auto"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <CardPayment
                    initialization={{
                        amount: amount,
                        preferenceId: referenceId,
                    }}
                    onSubmit={async (param) => {
                        executePayment(param);
                    }}
                    locale={locale}
                    customization={{
                        paymentMethods: {
                            types: {
                                excluded: ['debit_card']
                            },
                            maxInstallments: 1
                        },
                        visual: {
                            texts: {
                                formTitle: formTitle,
                                formSubmit: formSubmit
                            },
                            style: {
                                theme: 'bootstrap',
                            }
                        }
                    }}
                />
            )}

            {showAlert && (
                <CustomAlert
                    variant='outlined'
                    severity={severityAlert}
                    message={messageAlert}
                    onClose={hideAlert}
                />
            )}
        </Box>
    );
}
