import emailjs from 'emailjs-com';

export function sendEmail(recipients, serviceId, templateId, userId) {
    if (recipients && serviceId && templateId && userId) {
        recipients.forEach(recipient => {

            try {
                const templateParams = {
                    to_email: recipient
                }

                emailjs.send(
                    serviceId,
                    templateId,
                    templateParams,
                    userId
                ).then((response) => {
                    console.log(response)
                    console.log('Email sent successfully!')
                }).catch((error) => console.error('Error trying send e-mail', error))
            } catch (error) {
                console.error('Error trying send email to nutritionists: ', error)
            }
        });
    }
}