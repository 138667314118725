import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Copyright from '../../components/material/copyright';
import { auth } from '../../services/firebase/auth'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { AppBar, LinearProgress, Toolbar } from '@mui/material';
import CustomAlert from '../../components/material/alert';
import { useUser } from '../../components/userContext';
import { getNutritionist } from './../../services/firebase/nutritionist';

import buttonStyle from './../../global/style/button';

import icon from './../../assets-screens/icon.png'

export default function Login() {
  const { setUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const hideAlert = () => {
    setShowAlert(false)
  }

  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(auth);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      setMessage('Informe seu email de acesso.');
    } else if (!password) {
      setMessage('Informe sua senha de acesso');
    } else {
      signInWithEmailAndPassword(email, password)
    }

    if (!email || !password) {
      setShowAlert(true);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          try {
            const nutritionist = await getNutritionist(user.user.email)

            if (nutritionist) {

              nutritionist.accessToken = user.user.accessToken

              if (nutritionist.status !== 'PENDING') {
                setUser(nutritionist)
                navigate('dashboard')
              } else {
                setMessage('Seu usuário ainda não está ativo. Aguarde nosso contato.');
                setShowAlert(true);
              }
            } else {
              setMessage('Erro, tente novamente em 30 min.');
              setShowAlert(true);
            }
          } catch (error) {
            console.error('Erro ao buscar o nutricionista:', error);
          }
        } else if (error) {
          setMessage('Dados de acesso inválidos');
          setShowAlert(true);
        }
      } catch (error) {
        setMessage('Ocorreu algum erro, tente novamente em 30 min.');
        setShowAlert(true);
      }
    };

    fetchData();

  }, [user, error, navigate, setUser]);


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AppBar position="absolute" sx={{ backgroundColor: '#248f4e' }}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <Typography variant="h6">Tech Studios AI</Typography>
        </Toolbar>
      </AppBar>
      {showAlert && (
        <CustomAlert
          variant='outlined'
          severity='error'
          message={message}
          onClose={hideAlert}
        />
      )}
      {loading && (
        <LinearProgress />
      )}
      <Box
        sx={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={icon}
          alt="Avatar"
          sx={{
            width: 100,
            height: 100,
            marginBottom: 1,
            backgroundColor: 'transparent'
          }}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 5
          }}
        >
          Dietfy
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ...buttonStyle, mt: 3, mb: 2 }}
          >
            Acessar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                to="/register"
                variant="body2"
                onClick={() => navigate('/register')}
                style={{ cursor: 'pointer' }}
              >
                Crie sua conta
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 60, mb: 4 }} />
    </Container>
  );
}