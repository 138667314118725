import React from 'react';
import Alert from '@mui/material/Alert';

const CustomAlert = ({ variant, severity, message, onClose, transform }) => {
  return (
    <Alert
      variant={variant}
      severity={severity}
      sx={{ transform: transform ? transform : 'translateY(80px)', paddingTop: '5' }}
      onClose={onClose}
    >
      {message}
    </Alert>
  );
};

export default CustomAlert;
