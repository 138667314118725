import React from 'react';
import 'chart.js/auto';

import { Chart } from 'react-chartjs-2';

export default function ComparisonChart(props) {
    const { monthOneName, monthTwoName, monthOneValue, monthTwoValue } = props;

    const months = [monthOneName, monthTwoName];
    const data = {
        labels: months,
        datasets: [
            {
                type: 'bar',
                label: 'Comparação',
                backgroundColor: 'rgb(75, 192, 192)',
                data: [monthOneValue, monthTwoValue],
                borderWidth: 2,
            },
        ],
    };

    return (
        <Chart data={data} />
    );
}
