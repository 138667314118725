import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBV8m3D2Jp3Aftb3_VueAfn8RQL7ErrSX8",
  authDomain: "dietfy-fazer-dieta.firebaseapp.com",
  projectId: "dietfy-fazer-dieta",
  storageBucket: "dietfy-fazer-dieta.appspot.com",
  messagingSenderId: "727916668396",
  appId: "1:727916668396:web:0730ac5ec00d02233a8cc9",
  measurementId: "G-CDHRFKQEJK"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app);

export {db, app}