import React, { useEffect, useState } from 'react';
import {
  Box,
  CssBaseline,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  TablePagination,
  Divider,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useUser } from '../../../components/userContext';
import { useNavigate } from 'react-router-dom';
import { getUserFromSessionStorage } from '../../../utils/userUtils';
import { getValidationsByFilter } from '../../../services/firebase/validation';
import { getDiet } from '../../../services/firebase/diets';
import { getCustomerByEmail } from '../../../services/firebase/customers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import buttonStyle from '../../../global/style/button';

export default function ReportsMonthly() {
  dayjs.locale('pt-br')

  const { user } = useUser();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const defaultRowsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const [loading, setLoading] = useState(true);
  const [validations, setValidations] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [queryExecuted, setQueryExecuted] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(30, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  const [total, setTotal] = useState('0.00');
  const [status, setStatus] = useState('Pendente');
  const [isPaid, setIsPaid] = useState(false);

  const setStatusFilter = (newStatus) => {
    setStatus(newStatus);
    setLoading(true);
    if (newStatus === 'Pago') {
      setIsPaid(true)
    } else {
      setIsPaid(false)
    }

    handleSearch();
  }

  const handleSearch = () => {
    setQueryExecuted(false)
  };

  const populateTotalValue = async (validations) => {
    let sum = 0.0;
    if (validations) {
      validations.forEach((validation) => {
        sum += parseFloat(validation.amount);
      });
      setTotal(sum);
    } else {
      setTotal('0');
    }
  }

  const handleOpenDetail = (detail) => {
    setSelectedDetail(detail);
  };

  const handleCloseDetail = () => {
    setSelectedDetail(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const userFirebase = user ? user : getUserFromSessionStorage();

        if (!userFirebase) {
          navigate('/');
        }

        if (userFirebase && !queryExecuted) {
          const validations = await getValidationsByFilter(
            userFirebase.email,
            selectedStartDate,
            selectedEndDate,
            isPaid
          );
          await populateTotalValue(validations);
          const formattedData = await Promise.all(
            validations.map(async (validation) => {
              const diet = await getDiet(validation.diet_id);
              const customer = await getCustomerByEmail(diet.email);

              return {
                id: validation.id,
                clientName: customer.name,
                updatedAt: validation.updated_at,
                status: validation.status === 'pending_validation' ? 'AGUARDANDO VALIDAÇÃO' : 'VALIDADA',
                diet: diet.diet,
              };
            }));

          setValidations(formattedData);
        }
      } catch (error) {
        console.error('Erro na busca de validações:', error);
      }
    }

    //ADICIONAR ISSO EM TODAS AS REQUESTS REALIZADAS NO FIREBASE
    if (!queryExecuted) {
      fetchData();
      setQueryExecuted(true);
      setLoading(false)
    }

  }, [queryExecuted, user, navigate, selectedStartDate, selectedEndDate, isPaid, status]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      margin="0 auto"
      width="900px"
    >
      <CssBaseline />
      <TableContainer
        component={Paper}
        elevation={4}
        style={{
          maxWidth: '100%',
          overflow: 'auto'
        }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            fontSize: '24px',
          }}
        >
          &nbsp; R$ {total}
        </Typography>

        <br />
        <br />
        <br />
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            marginLeft: '17px'
          }}
        >
          Data da validação
        </Typography>
        <Box
          display="flex"
          marginLeft='17px'
        >
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              label="Inicio"
              value={selectedStartDate}
              onChange={(newValue) => setSelectedStartDate(newValue)} />
            <DatePicker
              label="Fim"
              value={selectedEndDate}
              onChange={(newValue) => setSelectedEndDate(newValue)}
            />
            <Box>
              <Autocomplete
                id="status-filter"
                options={['Pendente', 'Pago']}
                defaultValue={'Pendente'}
                getOptionLabel={(option) => option}
                noOptionsText={'Pendente'}
                value={status}
                onChange={(event, newValue) => {
                  setStatusFilter(newValue);
                }}
                sx={{
                  width: '152px',
                  marginTop: '-16px'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    margin="normal"
                  />
                )}
              />
            </Box>
          </DemoContainer>

        </Box>
        <Divider
          sx={{
            marginTop: '20px',
            borderBottom: '2px solid #E0E0E0',
          }}
        />
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '170px' }}>Cliente</TableCell>
              <TableCell style={{ width: '170px' }}>Status</TableCell>
              <TableCell style={{ width: '170px' }}>Validada em</TableCell>
              <TableCell style={{ width: '170px' }}>Dieta</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginRight="-600px"
              marginTop="35px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {validations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((validation) => (
                  <TableRow key={validation.id}>
                    <TableCell>{validation.clientName}</TableCell>
                    <TableCell>{validation.status}</TableCell>
                    <TableCell>{validation.updatedAt}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{ ...buttonStyle, marginLeft: '-18px' }}
                        onClick={() => {
                          handleOpenDetail(validation.diet);
                        }}
                      >
                        Exibir
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              }
              {validations.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      fontWeight: 'bold'
                    }}
                  >
                    Nenhuma validação localizada
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="div"
          count={validations.length}
          labelRowsPerPage="Linhas por página"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={selectedDetail !== null}
        onClose={handleCloseDetail}
        PaperProps={{
          style: {
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
            maxWidth: '60vw',
          }
        }}
      >
        <DialogTitle>Dieta</DialogTitle>
        <DialogContent>
          <Typography>
            {selectedDetail !== null
              ? selectedDetail.split('\n').map((text, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {text}
                </React.Fragment>
              ))
              : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleCloseDetail}
            sx={buttonStyle}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}