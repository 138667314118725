import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { Box, Button, Dialog, Typography } from '@mui/material'; // Importando Dialog e Typography
import buttonStyle from '../../global/style/button';
import { logoutUser } from '../../services/firebase/logout';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext';
import ProfileEditDialog from './profile';
import { Transition } from '../transition';
import LogoutIcon from '@mui/icons-material/Logout';

export default function NotificationsMenu() {
    const user = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
    const [initText, setInitText] = useState(null);
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);

    const handleOpenProfileDialog = () => {
        setProfileDialogOpen(true);
    };

    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await logoutUser();
            navigate('/');
        } catch (error) {
            console.error('Não foi possível deslogar.' + error);
        }
    }

    const handleCloseSupportDialog = () => {
        setIsSupportDialogOpen(false);
    }

    const handleCloseProfileDialog = () => {
        setProfileDialogOpen(false);
    };

    useEffect(() => {
        if (user && user.user && user.user.firstName) {
            setInitText(`Olá ${user.user.firstName.trim()},`)
        } else {
            setInitText('Caro usuário,')
        }
    }, [user, initText])

    return (
        <Box>
            <IconButton
                sx={{
                    color: 'white',
                    padding: 2,
                    marginBottom: -1.6,
                    backgroundColor: buttonStyle.backgroundColor,
                    position: 'relative',
                }}
                onClick={handleOpenMenu}
            >
                <Badge color="secondary">
                    <Avatar sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: buttonStyle.backgroundColor
                    }}>
                        <PersonIcon sx={{
                            color: 'white',
                            backgroundColor: buttonStyle.backgroundColor
                        }} />
                    </Avatar>
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '160px'
                    }}
                    onClick={() => handleOpenProfileDialog(true)}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Avatar
                            sx={{
                                width: 32,
                                height: 32,
                                color: 'white',
                                backgroundColor: "#007800"
                            }}>
                            <PersonIcon />
                        </Avatar>
                        <span style={{ marginLeft: '8px' }}>Perfil</span>
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '160px',
                    }}
                    onClick={() => setIsSupportDialogOpen(true)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 32,
                                height: 32,
                                color: 'white',
                                backgroundColor: "#007800"
                            }}>
                            <PersonIcon />
                        </Avatar>
                        <span style={{ marginLeft: '8px' }}>Suporte</span>
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '160px',
                    }}
                    onClick={handleLogout}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <Avatar
                            sx={{
                                width: 32,
                                height: 32,
                                color: 'white',
                                backgroundColor: "#FF0000"
                            }}
                        >
                            <LogoutIcon />
                        </Avatar>
                        <span style={{ marginLeft: '8px' }}>Sair</span>
                    </Box>
                </MenuItem>
            </Menu>

            <Dialog
                open={isSupportDialogOpen}
                onClose={handleCloseSupportDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        maxWidth: 800,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                }}
            >
                <Typography variant="h5">
                    {initText}
                </Typography>
                <Typography variant="body1">
                    <strong>Estamos aqui para ajudar!</strong>
                    <br /><br />
                    Se você está enfrentando algum problema ou precisa de suporte,
                    entre em contato conosco pelo e-mail <strong>techstudiosai@gmail.com</strong>.
                    <br /><br />
                    Garantimos que seu problema será tratado com a máxima prioridade. Nossa equipe se compromete a resolver o problema antes de 1 hora e notificá-lo assim que a solução estiver disponível.
                    <br /><br />
                    Para um atendimento mais eficiente, por favor, forneça detalhes sobre o que aconteceu,
                    onde você clicou,
                    qual tela apresenta o problema,
                    qualquer erro exibido e
                    quais ações você tentou realizar.
                    Além disso, se possível,
                    anexe capturas de tela (print-screens) para nos ajudar a entender melhor a situação.
                    <br /><br />
                    <strong>Agradecemos por sua compreensão e estamos ansiosos para lhe ajudar</strong>.
                </Typography>
                <Button
                    sx={{ ...buttonStyle, marginTop: 5 }}
                    variant="contained"
                    onClick={handleCloseSupportDialog}
                >
                    Fechar
                </Button>
            </Dialog>
            <ProfileEditDialog open={isProfileDialogOpen} onClose={handleCloseProfileDialog} />
        </Box>
    );
}
