import React, { useState } from 'react';
import { Tabs, Tab, Typography, TextareaAutosize, Button, Dialog, DialogContent } from '@mui/material';
import { TabPanel } from './../panel/index';
import buttonStyle from './../../../global/style/button';

const textareaStyle = {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    resize: 'both',
};

const tabPanelStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90%',
};

export default function TabsComponent(props) {
    const { diet, onSave, open, onClose } = props;
    const [value, setValue] = useState(0);
    const [editedDiet, setEditedDiet] = useState(diet.diet);
    const dietId = diet.id;
    const validation = diet.validation[0]
    
    const handleDietChange = (event) => {
        setEditedDiet(event.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflowX: 'hidden',
                }}
            >
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    sx={{ width: '100%' }}
                >
                    <Tab label="Solicitação" sx={{ width: '50%' }} />
                    <Tab label="Dieta" sx={{ width: '50%' }} />
                </Tabs>
                <TabPanel value={value} index={0} sx={{ overflowY: 'auto' }}>
                    <Typography variant="h6">Solicitação</Typography>
                    <Typography>
                        {validation.description}
                    </Typography>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                    sx={tabPanelStyle}
                >
                    <TextareaAutosize
                        minRows={30}
                        maxRows={35}
                        name="diet"
                        value={editedDiet}
                        onChange={handleDietChange}
                        style={textareaStyle}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => {
                            onSave(editedDiet, dietId, validation);
                            onClose();
                        }}
                        sx={{ ...buttonStyle, mt: 'auto', alignSelf: 'flex-start' }}
                    >
                        Concluir
                    </Button>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}
