import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import buttonStyle from '../../../global/style/button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '30px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
    padding: theme.spacing(1),
    maxWidth: '60vw',
  },
  '& .MuiDialogTitle-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export default function AnamneseDialog({ open, onClose, anamnese, username }) {
  return (
    <BootstrapDialog onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>Anamnese de {username}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Peso:
          </span>
          {anamnese.weight}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Altura:
          </span>
          {anamnese.height}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Objetivo:
          </span>
          {anamnese.goal}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Consume bebida alcoólica:
          </span>
          {anamnese.alcoholConsumer}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Horas de sono:
          </span>
          {anamnese.sleepHours}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Costuma ter insônia:
          </span>
          {anamnese.hasInsomnia}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Ocupação:
          </span>
          {anamnese.occupation}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginRight: '5px',
              marginTop: '8px'
            }}
          >
            Sente desconforto com:
          </span>
          {anamnese.discomfort ? anamnese.discomfort : 'Não informado'}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Pratica atividade física:
          </span>
          {anamnese.isPhysicalActivity}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Pratica atividade durante:
          </span>
          {anamnese.duration}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Ingestão de água:
          </span>
          {anamnese.waterIntake}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Alergia ou intolerância alimentar:
          </span>
          {anamnese.allergy}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Horário de maior disposição:
          </span>
          {anamnese.peakEnergyTime}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Funcionamento do intestino:
          </span>
          {anamnese.bowelMovement}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Período(s) de maior apetite:
          </span>
          {anamnese.hungerTime}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Tem uma mastigação:
          </span>
          {anamnese.chewingSpeed}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Frequência em restaurantes:
          </span>
          {anamnese.restaurantFrequency}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Preferência alimentar:
          </span>
          {anamnese.foodPreference}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Refeições do dia que costuma realizar:
          </span>
          {anamnese.mealSchedule}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Doenças familiares:
          </span>
          {anamnese.hasSomeFamilyDisease}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Medicamentos que costuma tomar:
          </span>
          {anamnese.medication}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            {anamnese.hasSurgery === 'Cirgurgias realizadas' ? anamnese.surgery : 'Já realizou cirurgia'}:
          </span>
          {anamnese.hasSurgery === 'Sim' ? anamnese.surgery : 'Não'}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Carboidratos que mais gosta:
          </span>
          {anamnese.foodRoutineIsEat.textInputCarbohydrates}
          {anamnese.foodRoutineIsEat.carbohydrate && `, ${anamnese.foodRoutineIsEat.carbohydrate}`}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Carboidratos que menos gosta:
          </span>
          {anamnese.foodRoutineIsNotEat.carbohydrate}
          {anamnese.foodRoutineIsNotEat.textInputCarbohydrates && (
            <>
              , {anamnese.foodRoutineIsNotEat.textInputCarbohydrates}
            </>
          )}
        </Typography>

        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Proteínas que mais gosta:
          </span>
          {anamnese.foodRoutineIsEat.protein}
          {anamnese.foodRoutineIsEat.textInputProtein && (
            <>
              , {anamnese.foodRoutineIsEat.textInputProtein}
            </>
          )}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Proteínas que menos gosta:
          </span>
          {anamnese.foodRoutineIsNotEat.protein}
          {anamnese.foodRoutineIsNotEat.textInputProtein && `, ${anamnese.foodRoutineIsNotEat.textInputProtein}`}
        </Typography>

        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Gorduras que mais gosta:
          </span>
          {anamnese.foodRoutineIsEat.fatAndOilSeeds}
          {anamnese.foodRoutineIsEat.textInputFatAndOilSeeds && `, ${anamnese.foodRoutineIsEat.textInputFatAndOilSeeds}`}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Gorduras que menos gosta:
          </span>
          {anamnese.foodRoutineIsNotEat.fatAndOilSeeds}
          {anamnese.foodRoutineIsNotEat.textInputFatAndOilSeeds ? `, ${anamnese.foodRoutineIsNotEat.textInputFatAndOilSeeds}` : ''}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Frutas que mais gosta:
          </span>
          {anamnese.foodRoutineIsEat.fruit}
          {anamnese.foodRoutineIsEat.textInputFruit ? `, ${anamnese.foodRoutineIsEat.textInputFruit}` : ''}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Frutas que menos gosta:
          </span>
          {anamnese.foodRoutineIsNotEat.fruit}
          {anamnese.foodRoutineIsNotEat.textInputFruit && `, ${anamnese.foodRoutineIsNotEat.textInputFruit}`}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Suplementos que consome:
          </span>
          {anamnese.foodRoutineIsEat.supplement}
          {anamnese.foodRoutineIsEat.textInputSupplement && `, ${anamnese.foodRoutineIsEat.textInputSupplement}`}
        </Typography>
        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Suplementos que não consome:
          </span>
          {anamnese.foodRoutineIsNotEat.supplement}
          {anamnese.foodRoutineIsNotEat.textInputSupplement && (
            <span>, {anamnese.foodRoutineIsNotEat.textInputSupplement}</span>
          )}
        </Typography>

        <Typography>
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              marginTop: '8px',
              marginRight: '5px',
            }}
          >
            Consome algum tipo de chá:
          </span>
          {anamnese.foodRoutineIsEat.teas}
          {anamnese.foodRoutineIsEat.textInputTeas ? `, ${anamnese.foodRoutineIsEat.textInputTeas}` : ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          autoFocus
          onClick={onClose}
          sx={buttonStyle}
        >
          Fechar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
