import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export async function getNutritionist(id) {
  const nutritionistRef = doc(db, 'nutritionist', id);

  try {
    const docSnap = await getDoc(nutritionistRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error trying get nutritionist by id:', error);
    return null;
  }
}

export async function getActiveNutritionists() {
  const nutritionistsRef = collection(db, 'nutritionist');

  try {
    const q = query(nutritionistsRef, where('status', '==', 'ACTIVE'));
    const querySnapshot = await getDocs(q);

    const nutritionists = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        nutritionists.push(doc.data());
      }
    });

    return nutritionists;
  } catch (error) {
    console.error('Error trying get all nutritionists with statys active:', error);
    return null;
  }
}
