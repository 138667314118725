import React from 'react';
import { BrowserRouter, Route, Routes as Switch, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import FoodMenu from './pages/FoodMenus';
import ReportsMonthly from './pages/Reports/Monthly';
import Payments from './pages/Payments';
import PaymentApp from './pages/PaymentApp';

import { UserProvider } from './components/userContext';

export default function Routes() {
    return (
        <UserProvider>
            <BrowserRouter>
                <Switch>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Navigate to="/" />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/dashboard" element={<Dashboard />}>
                        <Route path="/dashboard/foodMenus" element={<FoodMenu />} />
                        <Route path="/dashboard/reports/monthly" element={<ReportsMonthly />} />
                        <Route path="/dashboard/payments/month" element={<Payments />} />
                    </Route>
                    <Route path="/payment-app" element={<PaymentApp />} />
                </Switch>
            </BrowserRouter>
        </UserProvider>
    );
}