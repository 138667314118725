import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export async function getConfigEmail() {
  const configEmailCollection = collection(db, 'config_email');

  try {
    const querySnapshot = await getDocs(configEmailCollection);
    const docSnap = querySnapshot.docs[0];

    if (docSnap) {
      return docSnap.data();
    } else {
      console.error('Collection config_email not found!');
      return null;
    }
  } catch (error) {
    console.error('Error trying get config_email collection:', error);
    return null;
  }
}
