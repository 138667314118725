import React from 'react';
import Box from '@mui/material/Box';
import { TableCell, TableRow } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TableRow
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      <TableCell colSpan={2}>
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
