import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import styles from './style';

import NotificationsMenu from '../../components/userData';
import { Chart } from "react-google-charts";
import { useUser } from '../../components/userContext';
import { getUserFromSessionStorage } from '../../utils/userUtils';
import { getValidationsByEmailAndStatus } from '../../services/firebase/validation';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: styles.backgroundColor,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),

);

export default function Dashboard() {
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [title, setTitle] = useState('Plataforma Dietfy');
  const [paymentMenuOpen, setPaymentMenuOpen] = useState(false);
  const [paymentsByMonthOpen, setPaymentsByMonthOpen] = useState(false);
  const [data, setData] = useState(null);
  const [userLogged, setUserLogged] = useState(null);
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [chartVisible, setChartVisible] = useState(true)

  const changeTitle = (title) => {
    localStorage.setItem('title', title);
    setTitle(title);
  }

  const navigate = useNavigate()
  const { user } = useUser();

  useEffect(() => {
    try {
      const currentRoute = location.pathname;
      if (currentRoute !== '/dashboard') {
        setChartVisible(false)
      }
      async function fetchData() {
        if (user) {
          setUserLogged(user);
          const dataFormmat = await getValidationsByEmailAndStatus(user.email, true)
          setData(dataFormmat);
        } else {
          const userData = getUserFromSessionStorage();
          setUserLogged(userData);

          const dataFormmat = await getValidationsByEmailAndStatus(userData.email, true)
          setData(dataFormmat);
          if (!userData) {
            navigate('/');
          }
        }
      }

      if (!queryExecuted) {
        fetchData();
        setQueryExecuted(true)
      }
    } catch (error) {
      console.error(error)
    }
  }, [user, navigate, userLogged, queryExecuted, location])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={-2}
          >
            <Box>
              <NotificationsMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: styles.backgroundColor,
            px: [1],
          }}
        >
          <IconButton sx={{
          }} onClick={toggleDrawer}>
            <span
              style={{
                display: 'inline-block',
                fontWeight: 'bold',
                marginRight: '50px',
                marginTop: '1px',
                color: "white",
                backgroundColor: styles.backgroundColor
              }}
            >
              MENU
            </span>
            <MenuOpenIcon sx={{
              backgroundColor: styles.backgroundColor,
              color: 'white'
            }} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton
            component={Link} to="/dashboard/foodMenus"
            onClick={() => {
              setTitle('Cardápios')
              setPaymentMenuOpen(false)
              setPaymentsByMonthOpen(false)
              setChartVisible(false)
            }}
          >
            <ListItemIcon>
              <RestaurantOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Cardápios" />
          </ListItemButton>
          <ListItemButton
            component={Link} to="/dashboard/reports/monthly"
            onClick={() => {
              setTitle('Relatórios')
              setPaymentMenuOpen(false)
              setPaymentsByMonthOpen(false)
              setChartVisible(false)
            }}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setPaymentMenuOpen(!paymentMenuOpen)
              setPaymentsByMonthOpen(false)
              setChartVisible(false)
            }
            }
            sx={{ marginBottom: 10 }}
          >
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Pagamentos" />
          </ListItemButton>
          {paymentMenuOpen && (
            <List>
              <ListItemButton
                component={Link} to="/dashboard/payments/month"
                onClick={() => {
                  changeTitle('Pagamentos')
                  setPaymentsByMonthOpen(true)
                  setChartVisible(false)
                }
                }
                sx={{
                  maxHeight: 25,
                  width: 100,
                  left: 70,
                  marginTop: '-90px'
                }}
              >
                <ListItemText
                  primary="Mensais"
                  primaryTypographyProps={{
                    style: {
                      fontWeight: paymentsByMonthOpen ? 'bold' : 'normal'
                    }
                  }}
                />
              </ListItemButton>
            </List>
          )}
        </List>
      </Drawer>
      <Outlet />
      {chartVisible && data && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <Chart
            chartType="PieChart"
            data={data}
            options={{
              title: 'Progresso Financeiro Anual',
              is3D: true,
              animation: {
                duration: 5000,
                easing: 'out',
              },
              width: 700,
              height: 800
            }}
          />
        </Box>
      )}
    </Box>
  );
}