import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from './../../services/firebase/firebase';
import CustomAlert from '../material/alert';

export const fetchPaymentData = async () => {
    try {
        const paymentDataCollection = collection(db, "payment_data");
        const paymentDataSnapshot = await getDocs(paymentDataCollection);

        const paymentData = paymentDataSnapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });

        return paymentData[0];
    } catch (error) {
        return <CustomAlert
            transform='translateY(-220px)'
            variant='outlined'
            severity='error'
            message='Erro ao tentar efetuar pagamento. Tente novamente mais tarde.'
        />
    }
};

export const saveTransactionData = async (transactionData) => {
    try {
        const transactionsCollection = collection(db, 'response_mercado_pago');

        const docRef = await addDoc(transactionsCollection, {
            diet_id: transactionData.diet_id,
            user_email: transactionData.user_email,
            created_at: transactionData.created_at,
            description: transactionData.description,
            data: transactionData.data
        });

        return docRef.id;
    } catch (error) {
        console.error(error)
        return <CustomAlert
            transform='translateY(-220px)'
            variant='outlined'
            severity='error'
            message='Erro ao tentar efetuar pagamento. Tente novamente mais tarde.'
        />
    }
};