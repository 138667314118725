import { createUserWithEmailAndPassword } from 'firebase/auth';

import { db } from '../firebase/firebase';
import { auth } from '../firebase/auth';
import { doc, setDoc } from 'firebase/firestore';



const registerUser = async (user) => {

    async function persistUserInAuth() {
        return await createUserWithEmailAndPassword(auth, user.email, user.password);
    }

    async function persistUserInDatabase() {        
        await setDoc(doc(db, 'nutritionist', user.email), user);

    }

    try {
        await persistUserInDatabase();
        return await persistUserInAuth().user;
    } catch (error) {
        throw error;
    }
};

export default registerUser;
